// Generated by Framer (04e0834)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["gCMGjCiPJ", "y7Uin0tKi"];

const serializationHash = "framer-McT9m"

const variantClassNames = {gCMGjCiPJ: "framer-v-1msvpat", y7Uin0tKi: "framer-v-1nd7gqi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "gCMGjCiPJ", "Variant 2": "y7Uin0tKi"}

const getProps = ({categoty, click, height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "gCMGjCiPJ", xchcqaJDW: click ?? props.xchcqaJDW, yT0nDL9yE: categoty ?? props.yT0nDL9yE ?? "Category"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;categoty?: string;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, yT0nDL9yE, xchcqaJDW, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "gCMGjCiPJ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap14yk8lt = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (xchcqaJDW) {const res = await xchcqaJDW(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1msvpat", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"gCMGjCiPJ"} onTap={onTap14yk8lt} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({y7Uin0tKi: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-79bn80"} layoutDependency={layoutDependency} layoutId={"diukSMii0"} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}} variants={{y7Uin0tKi: {backgroundColor: "var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38))"}}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FzcGVrdGEgNDAw", "--framer-font-family": "\"Aspekta 400\", \"Aspekta 400 Placeholder\", sans-serif", "--framer-letter-spacing": "-0.03em", "--framer-line-height": "1.4em"}}>Category</motion.p></React.Fragment>} className={"framer-59y2ow"} fonts={["CUSTOM;Aspekta 400"]} layoutDependency={layoutDependency} layoutId={"aaHvSiBSu"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={yT0nDL9yE} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-McT9m.framer-17zlaot, .framer-McT9m .framer-17zlaot { display: block; }", ".framer-McT9m.framer-1msvpat { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-McT9m .framer-79bn80 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 4px 20px 4px 20px; position: relative; width: min-content; }", ".framer-McT9m .framer-59y2ow { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-McT9m.framer-1msvpat, .framer-McT9m .framer-79bn80 { gap: 0px; } .framer-McT9m.framer-1msvpat > *, .framer-McT9m .framer-79bn80 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-McT9m.framer-1msvpat > :first-child, .framer-McT9m .framer-79bn80 > :first-child { margin-left: 0px; } .framer-McT9m.framer-1msvpat > :last-child, .framer-McT9m .framer-79bn80 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 104
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"y7Uin0tKi":{"layout":["auto","auto"]}}}
 * @framerVariables {"yT0nDL9yE":"categoty","xchcqaJDW":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerE1QNfNUQ4: React.ComponentType<Props> = withCSS(Component, css, "framer-McT9m") as typeof Component;
export default FramerE1QNfNUQ4;

FramerE1QNfNUQ4.displayName = "Blogs / Filtering Button";

FramerE1QNfNUQ4.defaultProps = {height: 30, width: 104};

addPropertyControls(FramerE1QNfNUQ4, {variant: {options: ["gCMGjCiPJ", "y7Uin0tKi"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, yT0nDL9yE: {defaultValue: "Category", displayTextArea: false, title: "Categoty", type: ControlType.String}, xchcqaJDW: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerE1QNfNUQ4, [{explicitInter: true, fonts: [{family: "Aspekta 400", source: "custom", url: "https://framerusercontent.com/assets/AEfYlIhKbMvfYJjz64K4roBkv1k.woff2"}]}], {supportsExplicitInterCodegen: true})